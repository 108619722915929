import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { Community } from 'store/community/type';
import moment from 'moment';
import { Branch, Retail } from 'store/space/type';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';

SwiperCore.use([Autoplay, Navigation]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LandingService: React.FC<{ data: any }> = ({ data }) => {
  const [currentMainSwiperIndex, setCurrentMainSwiperIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const storySwiperRef = useRef(null);
  const branchSwiperRef = useRef(null);
  const brandSwiperRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="main-wrap" style={{ overflow: 'hidden' }}>
      <div className="top-bill">
        <Swiper
          onTransitionEnd={e => setCurrentMainSwiperIndex(e.activeIndex)}
          observeParents
          observeSlideChildren
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
        >
          {data.video && (
            <SwiperSlide className="viewport">
              <SwiperContent
                style={{
                  height: 'auto',
                  cursor: data.link_target ? 'pointer' : 'auto',
                }}
                onClick={() =>
                  data.link_target && window.open(data.link_target, '_blank')
                }
              >
                <video
                  autoPlay
                  controls={false}
                  loop
                  muted
                  playsInline
                  style={{ width: '100%' }}
                >
                  <source
                    src={`${data.video}?playsinline=1`}
                    type="video/mp4"
                  />
                </video>
              </SwiperContent>
            </SwiperSlide>
          )}
          {data.images.map(({ image, mobile_image, link_target }, idx) => (
            <SwiperSlide key={idx} className="viewport">
              <SwiperContent
                image={isMobile && mobile_image ? mobile_image : image}
                style={
                  isMobile && mobile_image
                    ? {
                        cursor: link_target ? 'pointer' : 'auto',
                      }
                    : {
                        paddingTop: '56.25%',
                        maxHeight: 0,
                        cursor: link_target ? 'pointer' : 'auto',
                      }
                }
                onClick={() =>
                  link_target && window.open(link_target, '_blank')
                }
              >
                <span>
                  CITY CREATOR LIFE STYLE - 지역과 도시의 창의적 생산자를 위한
                  라이프스타일 멤버십
                </span>
              </SwiperContent>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="controller">
          {data.video && (
            <a
              className={currentMainSwiperIndex === 0 ? 'selected' : undefined}
            />
          )}
          {data.images.map((_, idx) => (
            <a
              key={idx}
              className={
                data.video
                  ? idx + 1 === currentMainSwiperIndex
                    ? 'selected'
                    : undefined
                  : idx === currentMainSwiperIndex
                  ? 'selected'
                  : undefined
              }
            />
          ))}
        </div>
      </div>
      <div className="m-membership">
        <MemberShipImgWrapper>
          <MembershipImg src="/images/membership-main1.jpg" />
        </MemberShipImgWrapper>
        <Link href="/membership">
          <a>MEMBERSHIP</a>
        </Link>
      </div>
      {/* <div> */}
      {/*  <MembershipTrial */}
      {/*    href="https://docs.google.com/forms/d/1eoyMQTnV4MTp5MB-bKcWWherN9xvapv2YYb7HmMYbs8/viewform?edit_requested=true" */}
      {/*    target="_blank" */}
      {/*  > */}
      {/*    <MembershipTrialImg src="/images/membership_trial_img.png" /> */}
      {/*  </MembershipTrial> */}
      {/* </div> */}
      <StoryContainer className="member-life">
        <h2>
          함께 살고
          <br />
          일하는 멤버들의
          <br />
          생생한 이야기
        </h2>
        <div style={{ display: 'flex', overflow: 'hidden' }}>
          <div
            className="swiper-button-prev"
            onClick={() => storySwiperRef.current.swiper.slidePrev()}
          />
          <StorySwiperContainer className="cnts">
            {/* @ts-ignore */}
            <Swiper slidesPerView="auto" wrapperTag="ul" ref={storySwiperRef}>
              {data.recent_post_list?.map(
                ({ id, branch, content, created_at }: Community) => (
                  <SwiperSlide
                    tag="li"
                    key={id}
                    style={{
                      width: 'min-content',
                    }}
                  >
                    <a href={`/branch/post/${id}`}>
                      <em className="cate">{branch.name}</em>
                      <div style={{ position: 'relative' }}>
                        <div
                          style={{
                            height: 100,
                            overflow: 'hidden',
                            marginTop: 16,
                          }}
                          dangerouslySetInnerHTML={{ __html: content }}
                        />
                        <FadeOut />
                      </div>
                      <span style={{ marginTop: 16 }}>
                        {moment(created_at).format('YYYY.MM.DD / hh:mm')}
                      </span>
                    </a>
                  </SwiperSlide>
                ),
              )}
            </Swiper>
          </StorySwiperContainer>
          <div
            className="swiper-button-next"
            onClick={() => storySwiperRef.current.swiper.slideNext()}
          />
        </div>
      </StoryContainer>
      <FullSwiperContainer className="locals-branch">
        <h2>
          Local Stitch <br />
          Branch
        </h2>
        <p>
          로컬스티치의 지점은 모두 다른 모습과 매력을 지니고 있습니다. 다양한
          색깔만큼 다채로운 이야기가 펼쳐지고 있는 로컬스티치의 지점을
          만나보세요.
        </p>
        <Link href="/branch/place/list">
          <a>BRANCH</a>
        </Link>
        <div className="sp-list">
          <div
            style={{
              display: 'flex',
              overflow: 'hidden',
            }}
          >
            <div
              className="swiper-button-prev"
              onClick={() => branchSwiperRef.current.swiper.slidePrev()}
            />
            <Swiper
              slidesPerView="auto"
              wrapperTag="ul"
              // @ts-ignore
              ref={branchSwiperRef}
              observer
              observeParents
            >
              {data.branch_list?.map(
                ({ id, name, address, representation_image }: Branch) => (
                  <SwiperSlide tag="li" key={id} style={{ width: 'unset' }}>
                    <a href={`/branch/place/${id}`}>
                      <img src={representation_image} alt="" />
                      <address>{address}</address>
                      <em>{name}</em>
                    </a>
                  </SwiperSlide>
                ),
              )}
            </Swiper>
            <div
              className="swiper-button-next"
              onClick={() => branchSwiperRef.current.swiper.slideNext()}
            />
          </div>
        </div>
      </FullSwiperContainer>
      <div className="local-brand">
        <h2>LOCAL BRAND</h2>
        <FullSwiperContainer className="lb-list">
          <div
            style={{
              display: 'flex',
              overflow: 'hidden',
            }}
          >
            <div
              className="swiper-button-prev"
              onClick={() => brandSwiperRef.current.swiper.slidePrev()}
            />
            {/* @ts-ignore */}
            <Swiper slidesPerView="auto" wrapperTag="ul" ref={brandSwiperRef}>
              {data.retail?.map(
                ({ id, introduction, images, name }: Retail) => (
                  <SwiperSlide
                    tag="li"
                    key={id}
                    style={{ width: 'min-content' }}
                  >
                    <a href={`/retail/${id}`}>
                      <span
                        className="thumb"
                        style={{ backgroundImage: `url(${images[0].image})` }}
                      />
                      <em>{name}</em>
                      <p>{introduction}</p>
                    </a>
                  </SwiperSlide>
                ),
              )}
            </Swiper>
            <div
              className="swiper-button-next"
              onClick={() => brandSwiperRef.current.swiper.slideNext()}
            />
          </div>
        </FullSwiperContainer>
      </div>

      {/* Temporarily hidden due to lack of content */}
      {/* <div className="local-story"> */}
      {/*  <img src="images/mbtm_storyimg.png" alt="ABOUT LOCAL STITCH - Local Stitch Story"/> */}
      {/* </div> */}
    </div>
  );
};

const FadeOut = styled.div`
  position: absolute;
  bottom: 0px;

  display: block;

  width: 100%;
  height: 40px;

  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 100%
  );
`;

const SwiperContent = styled.div<{ image?: string }>`
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;
  min-width: auto !important;
  width: 100%;
  object-fit: contain;
`;

const StoryContainer = styled.div`
  padding-right: 0;

  .swiper-button-next {
    position: static;
    margin: auto 0 auto 12px;
    color: black;
  }
  .swiper-button-prev {
    position: static;
    margin: auto 12px auto 0;
    color: black;
  }
`;

const StorySwiperContainer = styled.div`
  width: auto !important;
  min-width: auto !important;
  padding: 0 !important;
  position: relative;
  margin-left: 0 !important;
`;

const FullSwiperContainer = styled.div`
  .swiper-button-next {
    position: static;
    margin: auto 90px auto 20px;
    color: black;
    @media screen and (max-width: 900px) {
      margin: auto 12px;
    }
  }
  .swiper-button-prev {
    position: static;
    margin: auto 20px auto 90px;
    color: black;
    @media screen and (max-width: 900px) {
      margin: auto 12px;
    }
  }
`;

const MemberShipImgWrapper = styled.div``;
const MembershipImg = styled.img`
  width: 100%;
  max-width: 900px;
`;
const MembershipTrial = styled.a`
  width: 100%;
  max-width: 600px;
  display: block;
  margin: auto;
  @media (min-width: 900px) {
    margin-top: 80px;
  }
  margin-top: 20px;
  padding: 0 20px;
  box-sizing: border-box;
`;
const MembershipTrialImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export default LandingService;
